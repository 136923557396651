// Use this for default Icomoon

@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: (woff, ttf, svg, eot),
    $style: normal,
);

@include fontFaceV2(
    $fontName: "Oswald", 
    $fileName: "oswald-v29-latin", 
    $weights: ("300", "600", "700"), 
    $types: all
);
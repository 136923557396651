/* default lightbox hover */
[data-rel^="lightbox"], 
a.lightboxImage {  
    @extend .marginBottom;
    display:table;
    width:100%;
    position:relative;
    text-decoration:none;
    &[href*=qr] {
        width:auto;
        &:before,
        &:after {
            content:none;
        }
    }
    &:before, &:after {
        @extend %animatedTransform;
        pointer-events:none;
    }
    &:before {
        @extend .icon;
        @extend .icon-plus;
        align-items:center;
        border-radius:50% 0 0 0;
        bottom:1rem;
        color:rgba($light, .95); 
        display:flex;
        font-size:1rem;
        height:2rem;
        justify-content:center;
        position:absolute;
        right:1rem;
        text-align:center;
        transform:translate3d(0, 0, 0);
        width:2rem;
        z-index:1;
        opacity: .8;
        @include breakpoint(giant) {
            font-size: rem(24px);
            bottom:.5rem;
            right:.5rem;
        }
    }
    &:hover, &:focus {
        &:before {
            border-radius:0;
            height:100%;
            width:100%;
            background-color:rgba($secondary, .5);
            font-size:2rem;
            bottom:0;
            right:0;
            opacity: 1;
        }
    }
}
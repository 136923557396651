// Form
.transform {
	position: relative;
	width: 100%;

	body.kontaktIndex & {
		padding-top: rem($baseGap*5);

		@include breakpoint(large) {
			padding-top: 0;
		}
	}

	::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
		color: transparent;
		font-family: $mainFont;  
	}

	::-moz-placeholder {
	/* Firefox 19+ */
		color: transparent;
		font-family: $mainFont;
	}

	:-ms-input-placeholder {
	/* IE 10+ */
		color: transparent!important;
		font-family: $mainFont!important;
	}

	:-moz-placeholder {
	/* Firefox 18- */
		color: transparent;
		font-family: $mainFont;  
	}

	fieldset {
		border: none;
	}

	.input {
		position: relative;
		display: block;
		width: 100%; 
		margin-bottom: 2.8rem;

		body.anfahrt & {
			margin-bottom: 0.8rem;
		}

		label {  
			position: absolute;
			display: block;
			top: 50%;
			left: rem($baseGap);
			transform: translate(0, -50%);
			color: $light;
			z-index: 2;
			transition: 300ms all;
			padding: 1rem 0;

			small {
				color: darken($light, 25%);
				display: inline-block;
				line-height: 0.5rem;
			}

			&[for="Nachricht"] {
				padding: 0;
			}

			&[for="Datenschutz"] {
				@extend .marginBottom;
				float: left;
				width: calc(100% - #{rem($baseGap)});
			}

			&[for="Rueckruf"] {
				width: calc(100% - #{rem($baseGap)});
			}
		}

		&.text {
			margin-bottom: 2.8rem;

			label {
				top: rem($baseGap);
				transform: none;
				padding-top: 0;
			}

			&.focused {
				label {
					color: $light;
					top: 0;
					transform: translate(0, -50px);
					padding: 1rem 0 0.6rem;
				}
			}
		}

		&.focused {
			label {
				color: $light;
				top: 0;
				transform: translate(0, -50px);
			}

			::-webkit-input-placeholder {
				color: $light;
			}

			::-moz-placeholder {
				color: $light;
			}

			:-ms-input-placeholder {
				color: $light !important;
			}

			:-moz-placeholder {
				color: $light;
			}
		}

		&.check {
			min-height: 3.5rem;
			padding: rem($baseGap);
			background: #13171b;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			border-left: 1px solid $tetriary;

			&.dse {
				min-height: 7.5rem;

				[for="Datenschutz"] {
					padding-right: 3rem;
				}

				@include breakpoint(tiny) {
					min-height: 5.5rem;
				}

				@include breakpoint(small) {
					min-height: 4rem;
				}

				@include breakpoint(large) {
					min-height: 6rem;
				}

				@include breakpoint(giant) {
					min-height: 4rem;
				}
			}
		}

		[type="text"],
		[type="tel"],
		[type="email"],
		[type="file"],
		input,
		textarea,
		select {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			min-height: 3.5rem;
			padding: rem($baseGap); 
			border: none;
			background: #13171b;
			border-left: 1px solid $tetriary;
			transition: 300ms all;
			color: $light;
			font-family: $mainFont;
			font-size: rem($baseFontSize);
			line-height: rem($baseLineHeight);
			margin: 0 0 0.5rem 0;
			resize: none;
			border-radius: 0;

			&:focus {
				background: darken($tetriary,20%);
			}
		}

		[type="checkbox"] {
			display: inline-block;
			float: right;
			margin: 0 0 0 1rem;
			padding: 0;
			width: rem(15px);
			height: rem(15px);
			min-height: rem(15px);

			body.internetExplorer & {
				border: none;
				
				&:focus {
					background: none;
				}
			}
		}

		[type="radio"] {
			clear: none;
			float: left;
			width: rem(15px);
			margin: 0.4rem 0.8rem 0 0;
			min-height: auto;
		}

		textarea {
			height: rem(250px);

			@include breakpoint(large) {
				height: rem(200px);
			}
		} 
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
		padding: rem($baseGap); 
		background: $tetriary;

		&:hover {
			background: darken($tetriary,20%);
		}
	}
}

/*=VALIDATION */
.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
// NAVIGATION
//////////////////////////////
#navigation {
	
	.branding {
		opacity:0;
		pointer-events: none;
		@extend %animatedTransform;
		width:rem(105px);
		display:none;

		@include breakpoint(large) {
			display:block;
		}
	}

	&.sticky {
		background:$primary;
		padding-top:rem(11px);
		padding-bottom:rem(19px);

		.branding {
			opacity:1;
			pointer-events: all;
		}
	}
}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	padding-top:rem(50px);
	padding-bottom:rem(100px);

	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);
	background-size:100% auto;
	background-position:top rem(50px) left;

	@include breakpoint(small) {
		padding-bottom:rem(150px);
		height:rem(350px);
	}

	@include breakpoint(medium) {
		height:rem(450px);
		padding-top:rem(110px);
		padding-bottom:rem(50px);
	}

	@include breakpoint(large) {
		height:rem(650px);
		padding-top:rem(200px);
		background-position: top left;
		padding-bottom:0;
		margin-bottom:rem(220px);
	}

	@include breakpoint(giant) {
		height:rem(800px);
		padding-top:rem(250px);
		margin-bottom:rem(100px);
	}

	@include breakpoint(full) {
		height:rem(1024px);
		padding-top:rem(333px);
	}

	.infoWrapper {
		display:none;

		@include breakpoint(large) {
			display:block;
		}
	}

	body:not(.index) & {
		@include responsiveImage('layout/bgSubHeader.jpg', (tiny, medium), true);
		background-position:top rem(50px) left;
		background-size:cover;
		padding-top:rem(80px);
		height:rem(250px);

		@include breakpoint(tiny) {
			padding-bottom:rem(60px);
		}

		@include breakpoint(small) {
			height:rem(250px);
		}

		@include breakpoint(medium) {
			height:rem(350px);
		}

		@include breakpoint(large) {
			// height:rem(632px);
			height:rem(400px);
			padding-top:rem(177px);
			padding-bottom:0;
			background-position: top left;
		}

		@include breakpoint(giant) {
			height:rem(632px);
		}
	}

	&.lazyLoaded {
		background-size:cover;
		background-position:top left;
		background-repeat:no-repeat;
	}

	.branding {
		display: block;
		max-width: rem(150px);
		width:100%;
		position: relative;
		z-index: 1;
		margin:0 auto;

		@include breakpoint(large) {
			margin-top:rem(-3px);
			margin-left:rem(22px);
			margin-right:0;
			max-width: rem(200px);
		}

		body.index & {

			margin-top:rem(30px);
			position:relative;

			

			@include breakpoint(large) {
				max-width: rem(300px);
				margin:0 auto;

				&:after {
					content:"";
					display:block;
					background:$secondary;
					width:1px;
					height:100%;
					position:absolute;
					top:0;
					right:rem(-50px);
				}
			}

			@include breakpoint(giant) {
				max-width:rem(300px);

				&:after {
					right:rem(-90px);
				}
				
			}

			@include breakpoint(full) {
				margin-left:rem(72px);
				max-width: rem(390px);
			}
		}
	}

	body:not(.index) & {
		.infoWrapper {
			display:none;

			@include breakpoint(large) {
				display:flex;
				justify-content: space-between;
				margin-top:rem(30px);
				position:relative;
			}

			&:before {
				width:1px;
				background:$secondary;
				height:rem(230px);
				display:block;
				content:"";
				position:absolute;
				left:rem(-104px);
				top:rem(-82px);

				@include breakpoint(giant) {
					left:rem(-120px);
				}

				@include breakpoint(huge) {
					left:rem(-168px);
				}
			}

			.infoNote {
				display:none;
			}

			.comma {
				display:none;
			}
			.townPostal {
				display:block;
			}

			.indexImage {
				display: none;
			}
		}
	}
}

// header info
.headerInfo,
.footerInfo {

	.title {
		font-weight: 600;
		text-transform: uppercase;
		font-size:rem(18px);
		line-height: rem(23px);
		letter-spacing: 0.05em;
		color:$light;
	}

	.info,
	dl,
	.infoNote,
	.phoneNum {
		color:$medium;
		font-size:rem(16px);
		line-height: rem(22px);
		letter-spacing: 0.05em;
	}

	.street {
		margin-bottom:rem(30px);
	}

	dl.workingHours {
		width:rem(135px);
		margin:0 auto;

		@include breakpoint(large) {
			width:100%;
			margin:0;
		}
		dt {
			margin:0;
			padding:0;
			text-align:left;

			@include breakpoint(large) {
				text-align:left;
				width:rem(73px);
			}
		}

		dd {
			text-align:right;

			header & {
				white-space: nowrap;
			}

			@include breakpoint(large) {
				width:calc(100% - #{rem(73px)});
				text-align: left;
			}
		}
	}

	.infoNote {
		margin-top:rem(10px);
		margin-bottom:rem(30px);
	}

	.phoneNum {
		margin-bottom:rem(30px);

		a {
			font-weight: 300;
		}
	}
	
	.mailTitle {
		margin-bottom:rem(-4px);

		#footer & {
			margin-bottom:0;
		}
	}

	a.email {
		color:$secondary;
		font-size:rem(16px);
		letter-spacing: 0.05em;
		display:inline-block;
		font-weight: 300;

		&:hover,
		&:focus,
		&:active {
			color:$light;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	body.index & {
		@include breakpoint(giant) {
			padding-top:rem(100px);
		}
		@include breakpoint(cut) {
			padding-top:rem(190px);
		}
	}

	body:not(.index) & {
		padding-top:rem(70px);
		padding-bottom:rem(30px);

		@include breakpoint(large) {
			padding-top:rem(54px);
			padding-bottom:rem(54px);
		}

		h1 {
			
			@include breakpoint(cut) {
				padding-bottom:rem(361px);
				position:relative;
				&:after {
					content:"";
					display:block;
					position:absolute;
					left:calc(15% + #{rem(12px)});
					bottom:rem(2px);
					height:rem(248px);
					width:1px;
					background:$secondary;
				}
			}
		}
	}

	body.anfahrt &,
	body.kontakt & {
		padding-bottom:rem(50px);
		@include breakpoint(large) {
			padding-bottom:rem(100px);
		}
		@include breakpoint(cut) {
			padding-bottom:rem(54px);
		}

		h1 {
			padding-bottom: 0;

			&:after {
				display: none;
			}
		}
	}

	@include breakpoint(cut) {
		body:not(.cmsBackend) & {
			.content {
		
				& > .row {
					position:relative;
					&:nth-of-type(2n-1) {
						padding-bottom:rem(367px);
						&:after {
							content:"";
							display:block;
							position:absolute;
							right:calc(15% + #{rem(27px)});
							bottom:rem(92px);
							height:rem(248px);
							width:1px;
							background:$secondary;
						}
					}
					&:nth-of-type(2n) {
						padding-bottom:rem(367px);
						&:after {
							content:"";
							display:block;
							position:absolute;
							left:calc(15% + #{rem(27px)});
							bottom:rem(67px);
							height:rem(248px);
							width:1px;
							background:$secondary;
						}
					}
				}
			}
		}		
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	p.kontaktInfo {
		margin-bottom:0;
		margin-top: rem($baseGap*4);
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.section {
		&.section01 {
			position:relative;

			@include breakpoint(large) {
				padding-bottom:rem(125px);
			}

			.bottomText {
				margin-bottom: rem(40px);
				@include breakpoint(small) {
					margin-bottom: rem(79px);
					
					&:before {
						margin-top:0;
					}
				}
			}

			.btn {
				padding-left:0;

				@include breakpoint(tiny) {
					padding-left:rem($baseGap);
				}
			}

			.imageWrapper {
				text-align:center;
				width:100%;
				
				@include breakpoint(large){
					pointer-events: none;
					position:absolute;
					bottom:rem(-50px);
					right:0;
					z-index:-1;
					text-align:right;
				}

				@include breakpoint(large) {
					bottom:rem(-130px);
				}

				@include breakpoint(giant) {
					bottom:rem(-270px);
				}

				@include breakpoint(full) {
					bottom:rem(-250px);
				}

				@include breakpoint(cut) {
					// bottom:rem(-137px);
					// right:rem(-184px);
					bottom: auto;
					top: 0;
					text-align: center;
					right: 0;
					// 
					&.lazyLoaded {
						transform: translateX(rem(320px)) translateY(rem(-260px));
					}
				}
				img {
					width:100%;
					max-width:rem(600px);
					height:auto;

					@include breakpoint(large) {
						width:100%;
					}

					@include breakpoint(giant) {
						max-width:rem(983px);
					}

					@include breakpoint(cut) {
						width:auto;
						max-width:none;
					}
				}
			}
		}

		&.section02 {
			padding-top:rem(50px);
			padding-bottom:rem(30px);

			@include breakpoint(giant) {
				padding-top:rem(120px);
				padding-bottom:rem(123px);
			}
		}

		&.section03 {
			background:$tetriary;
			@include responsiveImage('layout/news-und-aktionen-woman.png', (tiny), true);
			padding-top:rem(44px);
			padding-bottom:rem(45px);
			position:relative;

			@include breakpoint(large,max) {
				background-image:none !important;
			}

			p {
				color:$light;

				br {
					display:none;

					@include breakpoint(giant) {
						display:block;
					}
				}
			}

			.btn {
				@include breakpoint(medium, max) {
					padding-left:0;
				}
			}

			img {
				margin-bottom:0;
				display:none;

				@include breakpoint(medium) {
					display:block;
					margin:0 auto;
					height:rem(300px);
				}

				@include breakpoint(full) {
					margin:0;
				}

				@include breakpoint(cut) {
					height:auto;
				}
			}

			&.lazyLoaded {
				background-repeat: no-repeat;
				background-position: right rem(222px) center;

				@include breakpoint(large) {
					background-position: right -30% center;
				}

				@include breakpoint(huge) {
					background-position: right -15% center;
				}

				@include breakpoint(cut) {
					background-position: right rem(222px) center;
				}
			}

			&:before,
			&:after {
				width:1px;
				height:rem(320px);
				display:none;
				content:"";
				position:absolute;
				background:$secondary;

				@include breakpoint(cut) {
					display:block;
				}
			}

			&:before {
				left:calc(43% + #{rem(5px)});
				top:rem(-159px);
			}

			&:after {
				right:calc(43% + #{rem(5px)});
				bottom:rem(-160px);
			}
			
		}

		&.section04 {
			// @include responsiveImage('layout/tattoo-man.jpg', (tiny), true);
			padding-top:rem(30px);
			padding-bottom:rem(50px);

			@include breakpoint(large) {
				padding-top:rem(100px);
				padding-bottom:rem(110px);
			}

			@include breakpoint(giant) {
				padding-top:rem(320px);
			}

			&.lazyLoaded {
				// background-position:left rem(87px) top rem(116px);
			}

			h3 {
				span {
					&.highlight {
						display:block;
					}
				}
			}

			.imageWrapper {				
				margin-left:rem(30px);
				@include breakpoint(tiny) {
					margin-left:rem(50px);
				}

				@include breakpoint(medium) {
					margin-left:0;
					text-align:center;
					width:100%;
				}

				@include breakpoint(large) {
					position:absolute;
					text-align:left;
					z-index:-1;
					pointer-events: none;
					
				}
				@include breakpoint(giant) {
					top:rem(-200px);
					left:rem($baseGap);
				}

				@include breakpoint(huge) {
					left:rem(40px);
				}

				@media screen and (min-width:1900px) {
					left:rem(-104px);
				}
				img {
					width:auto;
					margin-bottom:0;

					@include breakpoint(medium) {
						max-width:rem(450px);
					}
					@include breakpoint(giant) {
						max-width:rem(550px);
					}

					@include breakpoint(full) {
						max-width:rem(600px);
					}

					@include breakpoint(cut) {
						max-width:none;
					}
				}
			}
		}

		&.section05 {
			padding-bottom:rem(447px);
			padding-top:rem(356px);
			position:relative;
			@include responsiveImage('layout/kontakt-section.jpg', (tiny), true);
			display:none;

			@include breakpoint(large) {
				display:block;
				padding-bottom:rem(200px);
				padding-top:rem(200px);
			}

			@include breakpoint(full) {
				padding-bottom:rem(447px);
				padding-top:rem(356px);
			}

			&.lazyLoaded {
				background-position:right rem(208px) top;

				@include breakpoint(full) {
					background-position:right rem(208px) top rem(153px);
				}
			}

			@include breakpoint(full) {
				&:before,
				&:after {
					height:rem(249px);
					width:1px;
					display:block;
					content:"";
					background:$secondary;
					position:absolute;
					left:23%;
				}
				&:before {
					top:0;
				}
				&:after {
					bottom:rem(120px);
				}
			}
			
			.kontaktFormular {
				font-size:rem(48px);
				line-height: rem(58px);
				text-transform: uppercase;
				color:$light;
				letter-spacing: 0.05em;
				margin-bottom:rem(29px);

				@include breakpoint(full) {
					font-size:rem(68px);
					line-height: rem(78px);
				}

				.topLine {
					font-weight: 600;
				}
			}
		}

		&.sectionLarge {
			padding-top:rem(60px);
			padding-bottom:rem(40px);

			@include breakpoint(small) {
				padding-top:rem(90px);
			}

			@include breakpoint(large) {
				padding-top:rem(110px);
			}

			@include breakpoint(giant) {
				padding-top:rem(170px);
				padding-bottom:rem(123px);
			}

			@include breakpoint(cut) {
				padding-top:0;
			}
		}		
	}

	.imageBlock {
		margin-bottom:rem($baseGap);

		@include breakpoint(small) {
			margin-bottom:rem(2*$baseGap);
		}
	}
}

body.faq, body.faq2, body.kontaktIndex {
	.section {
		.content {
			.row {
				&:nth-of-type(2n) {
					padding-top:rem(60px);

					@include breakpoint(large) {
						padding-top:rem(100px);
					}

					@include breakpoint(giant) {
						padding-top:rem(120px);
					}

					@include breakpoint(cut) {
						padding-top:0;
					}
				}
			}
		}

		@include breakpoint(large) {
			padding-top: rem(100px);
		}

		@include breakpoint(giant) {
			padding-top:rem(120px);
			padding-bottom: 0;
		}

		@include breakpoint(cut) {
			padding-top:0;
			padding-bottom: 0;
		}
	}
}

// Mitwachsende Box für .col
body:not(.cmsBackend) .contentBox {
    z-index: 0;
    position: relative;
    padding:rem($baseGap) rem($baseGap*2); 
    margin:rem($baseGap*2) 0;

    @include breakpoint(large) {
    	padding:rem($baseGap*2) rem($baseGap*3);
    }
    > *:last-child {
    	margin-bottom: 0;
    }
    &:before {
        content:'';
        background: $tetriary;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
    }

    &.team {
    	h6 {
    		margin-top: rem($baseGap*2);

    		@include breakpoint(huge) {
    			font-size: rem(34px);
    		}
    	}
    }
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $dark;
	position:relative;	

	@include breakpoint(large) {
		&:after {
			content:"";
			width:rem(135px);
			height:rem(172px);
			display:block;
			background-image:image-url('layout/anchor.png');
			position:absolute;
			right:calc(11% + #{rem(-36px)});
			bottom:rem(23px);
		}
	}

	.mapCol {
		@include breakpoint(large) {
			max-width:calc(50% - #{rem($baseGap)});
			flex-basis:calc(50% - #{rem($baseGap)});
		}

		.googleMaps {
			height:rem(300px);
			@include breakpoint(large) {
				height:100%;
			}
		}
	}

	.footerInfo {		
		padding-left:rem($baseGap);
		padding-right:rem($baseGap);
		padding-top:rem(30px);
		padding-bottom:rem(30px);
		color:$medium;
		font-size:rem(16px);
		line-height:rem(22px);
		text-align:center;

		@include breakpoint(large) {
			padding-left:rem(30px);
			max-width:calc(50% + #{rem($baseGap)});
			flex-basis:calc(50% + #{rem($baseGap)});
			padding-bottom:rem(60px);
			text-align:left;
		}

		@include breakpoint(giant) {
			padding-left:rem(70px);
			padding-top:rem(61px);
			padding-bottom:rem(82px);
		}

		@include breakpoint(full) {
			padding-left:rem(100px);
		}

		@include breakpoint(cut) {
			padding-left:rem(171px);
		}
	}

	.clientName {
		font-size:rem(28px);
		font-weight: 600;
		line-height: rem(48px);
		text-transform: uppercase;
		margin-bottom:rem(30px);
		color:$light;
		letter-spacing: 0.05em;

		@include breakpoint(large) {
			font-size:rem(40px);
			line-height: rem(88px);
		}

		@include breakpoint(giant) {
			font-size:rem(50px);
			margin-bottom:rem(58px);
		}

		@include breakpoint(huge) {
			font-size:rem(58px);
		}

		.highlight {
			color:$tetriary;
		}
	}

	.infoWrapper {

		@include breakpoint(large) {
			display:flex;
			justify-content: flex-start;
		}

		.infoCol {
			&:first-of-type {
				position: relative;
				
				@include breakpoint(large) {
					&:after {
						content:"";
						display:block;
						width:1px;
						height:100%;
						position:absolute;
						top:0;
						right:rem(-35px);
						background:$secondary;
					}
				}

				@include breakpoint(giant) {
					&:after {
						right:rem(-62px);
					}
					
				}

				@include breakpoint(huge) {
					&:after {
						right:rem(-92px);
					}
					
				}
			}
			&:nth-of-type(2) {		
				@include breakpoint(large) {
					margin-left:rem(80px);
				}
				
				@include breakpoint(giant) {
					margin-left:rem(120px);
				}
				@include breakpoint(huge) {
					margin-left:rem(178px);
				}
			}
		}
	}
}
// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $medium; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	text-decoration: none;
	color: $navColor;
	@extend %animatedTransform;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-weight: 300;
	font-size:rem(18px);
	line-height: rem(23px);
	position:relative;

	&:before {
		@extend %animatedTransform;
	}
}
%buttonHover {
	// Link Hover
	color: $light;

	&:before {
		opacity:1;
	}
}
%buttonActive {
	// Link Active
	color:$light;
	font-weight: 700;

	&:before {
		opacity:1;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	@extend %animatedTransform;

	nav {
		@extend %animatedTransform;
		
		@include ul {
			&.navi {
				li {
					&.facebook {
						@extend %animatedTransform;
						img {
							width:rem(20px);
							margin-right:rem(10px);
						}
					}
					@include navButtons {
						@include navButton;						
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background:$secondary;
								color:$light;

								@include breakpoint($break) {
									background:$dark;
									color:$medium;
								}
							}
						}
					}
					@include navBack {
						position:relative;
						padding-left:rem(30px);
						border-bottom:1px solid  rgba(255,255,255,.2);

						span {
							border-bottom:0 none;
						}
						@include breakpoint($break) {
							padding-left:0;
							border-bottom:0 none;
						}
						&:before {
							position:absolute;
							left:0;
							top:rem(10px);

							@include breakpoint($break) {
								position:relative;
								left:auto;
								top:auto;

								body.ipad & {
									left:unset;
									top:unset;
								}
							}
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background:transparent;

		&.sticky {
			nav {
				padding-top:rem(60px);
			}
		}
		
		nav {
			padding-top:rem(78px);
			ul {
				&.navi {
					li {
						&.facebook {
							margin-top:rem(-2px);
							
							img {
								margin-right:0;
							}
							
							a {
								&:before {
									display:none;
								}
							}

							span {
								display:none;
							}

							&:hover,
							&:focus,
							&:active {
								opacity:.8;
							}
						}
						&.anfahrt,
						&.impressum,
						&.datenschutzerklaerung {
							display:none;
						}

						&.hasSub {
							.sub {
								padding:rem($baseGap);
								min-width:rem(150px);

								li {
									border-bottom:1px solid rgba(255,255,255,.2);

									a {
										padding-top:rem(12px);
										padding-bottom:rem(12px);
									}

									&:last-of-type {
										border-bottom:0 none;
									}

									&.navBack {
										border-bottom:0 none;
									}
								}
							}
						}
						&.navBack {
						}
					}

					& > li {
						& > a,
						& > span {
							&:before {
								content:"";
								display:block;
								background:#6291b6;
								width:1px;
								height:rem($baseGap);
								position:absolute;
								left:50%;
								transform:translateX(-50%);
								bottom:rem(-27px);
								opacity:0;
							}
						}

						&.hasSub {
							& > span,
							& > a {
								&:before {
									left:calc(50% - #{rem(8px)});
								}
							}
						}
						
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	margin-top:rem(30px);

	@include breakpoint(small) {
		display:flex;
		justify-content: center;
	}

	@include breakpoint(large) {
		margin-top:rem(-10px);
		display:block;
	}
	li {
		text-align:center;
		border-bottom:1px solid  rgba(255,255,255,.2);

		&.faq,
		&.kontakt,
		&.anfahrt {
			display:none;

			@include breakpoint(large) {
				display:block;
			}
		}

		&:last-of-type {
			border-bottom:0 none;
		}

		@include breakpoint(small) {
			text-align:left;
			border-bottom:0 none;
			margin-right:rem(30px);

			&:last-of-type {
				margin-right:0;
			}
		}

		@include breakpoint(large) {
			margin-right:0;
		}

		a {
			font-size:rem(16px);
			line-height: rem(40px);
			font-weight: 300;
			color:$medium;
			text-transform: uppercase;
			display:block;
			padding-bottom:rem(7px);
			padding-top:rem(7px);

			@include breakpoint(small) {
				padding-top:0;
				padding-bottom:0;
				display:inline-block;
			}

			&:hover,
			&:focus,
			&:active,
			&.active {
				color:$secondary;
			}
		}
	}
}
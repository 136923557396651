* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
  background:$secondary;
  color:$light;
}
::-moz-selection {
  background:$secondary;
  color:$light;
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: #fff;
  box-sizing: border-box;
  color: $medium;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  font-weight: 300;
  hyphens: manual;
  line-height: rem($baseLineHeight);
  letter-spacing: 0.05em;
  overflow-x:hidden;
  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;

  &[data-fw]  {
    background:$primary;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $dark;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondary;
    border-right: 8px solid $dark;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $tetriary;
  }
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  overflow-x:hidden;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

.googleMaps iframe,
.googleMaps [data-iframe] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
    filter: gray; /* IE6-9 */
	  -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
	  -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  font-weight: 600;
  color:$light;
  text-transform: uppercase;
  letter-spacing: 0.055em;

  .highlight {
    color:$secondary;

    .lightSection & {
      color:$primary;
    }
  }

  span.light {
    display:block;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  p + & {
    margin-top: rem($baseGap*3);
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

strong {
  font-weight: 600;
  color:$light;
}

h1,
.h1 {
  font-family: $displayFont;
  font-size: rem(24px);
  line-height: rem(30px);
  margin-bottom:rem(30px) !important;

  @include breakpoint(small) {
    font-size:rem(28px);
    line-height: rem(36px);
  }

  @include breakpoint(medium) {
    font-size:rem(38px);
    line-height: rem(44px);
  }

  @include breakpoint(large) {
    margin-bottom:rem(50px) !important;
    font-size: rem(48px);
    line-height: rem(54px);

    body.datenschutzerklaerung & {

      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h1Size);
    line-height: rem(88px);
  }

  @include breakpoint(cut) {
    margin-bottom:rem(109px) !important;
  }

  .highlight {
    color:$tetriary;
  }
}

h2,
.h2 {
  font-size: rem(22px);
  line-height: rem(32px);
  margin-bottom:rem(27px) !important;

  body.anfahrt & {
    margin-bottom:rem(10px) !important;
  }

  @include breakpoint(small) {
    font-size:rem(28px);
    line-height: rem(38px);
  }

  @include breakpoint(medium) {
    font-size:rem(32px);
    line-height: rem(42px);
  }

  @include breakpoint(large) {
    font-size: rem(38px);
    line-height: rem(48px);

    body.datenschutzerklaerung & {
      font-size: rem(38px);
      line-height: rem(48px);
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h2Size);
    line-height: rem(78px);
  }
}

h3,
.h3 {
  font-size: rem(20px);
  line-height: rem(30px);

  @include breakpoint(small) {
    font-size:rem(24px);
    line-height: rem(34px);
  }

  @include breakpoint(medium) {
    font-size:rem(28px);
    line-height: rem(38px);
  }

  @include breakpoint(large) {
    font-size: rem(32px);
    line-height: rem(42px);

    body.datenschutzerklaerung & {
      font-size: rem(32px);
      line-height: rem(42px);
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h3Size);
    line-height: rem(78px);
  }
  

  .highlight {
    color:$tetriary;

    .lightSection & {
      color:$primary;
    }
  }
}

h4,
.h4 {
  font-size: rem(20px);
  line-height: rem(30px);

  @include breakpoint(small) {
    font-size:rem(24px);
    line-height: rem(34px);
  }

  @include breakpoint(medium) {
    font-size:rem(28px);
    line-height: rem(38px);
  }

  @include breakpoint(large) {
    font-size: rem(32px);
    line-height: rem(42px);

    body.datenschutzerklaerung & {
      font-size: rem(32px);
      line-height: rem(42px);
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h4Size);
    line-height: rem(78px);
  }
}

h5,
.h5 {
  font-size: rem(19px);
  line-height: rem(29px);

  @include breakpoint(small) {
    font-size:rem(24px);
    line-height: rem(34px);
  }

  @include breakpoint(medium) {
    font-size:rem(28px);
    line-height: rem(38px);
  }

  @include breakpoint(large) {
    font-size:rem(32px);
    line-height: rem(42px);

    body.datenschutzerklaerung & {
      font-size:rem(32px);
      line-height: rem(42px);
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h5Size);
    line-height: rem(68px);
  }
}

h6,
.h6 {
  font-size: rem(18px);
  line-height: rem(28px);

  @include breakpoint(small) {
    font-size:rem(20px);
    line-height: rem(30px);
  }

  @include breakpoint(medium) {
    font-size:rem(24px);
    line-height: rem(34px);
  }

  @include breakpoint(large) {
    font-size: rem(28px);
    line-height: rem(38px);

    body.datenschutzerklaerung & {
      font-size: rem(28px);
    line-height: rem(38px);
    }
  }

  @include breakpoint(huge) {
    font-size: rem($h6Size);
    line-height: rem(58px);
  }
}

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  text-decoration: none;
  @extend %animatedTransform;
  font-weight: 600;

  &:focus,
  &:hover,
  &:active {
    color: $medium;
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: none;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 1rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(20px);
      margin-bottom: rem(7px);
      position:relative;

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        position:absolute;
        left:0;
        top:rem(7px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: $tetriary;
      color:$light;
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;

    &.lazyLoaded {
      background-image:none;
    }
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}

.giant-suffix-0 {
  @include breakpoint(giant) {
    margin-right:0;
  }
}
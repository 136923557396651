// text
$colorLight: #fff;
$colorMedium: #eee;
$colorLink: #eee;
$colorDark: #464a4d;

// backgrounds
$bgForm: #464a4d;
$bgMain: #333;
$bgPlaceholder: #ffefba;
$bgSubmit: #3bb54a;
$bgCheckbox: #888a8c;
$bgCheckSlider: #fff;

// misc
$borderStroke: 2px;
$spacing: 10px;
$borderRadius: 4px;
$fontSize: 11px;
$gaps: 18px;

#privacyCtrl {
  background: $bgMain;
  max-height: calc(100vh - 40px);
  padding: 0;
  border-radius: $borderRadius;

  &, *
  {
	  font-family: Arial, sans-serif;
  }

  &.hide,
  .hide {
	opacity: 0;
	visibility: hidden;
  }
  &.open {
	opacity: 1;
	visibility: visible;
  }
  .open & {
	height: calc(100vh - 40px);
  }

  a {
	color: $bgMain;
  }
}
#privacyForm {
  fieldset {
	border: none;
	border-left: $borderStroke solid $bgMain;
	padding: $spacing;
	padding-bottom: 0;
	p {
	  padding-right: 2.5em;
	  margin-bottom: 0;
	}
	&:not(:last-child) {
	  border-bottom: $borderStroke solid $bgMain;
	}
	a {
	  color: $colorLink;
	}
  }
  label {
	cursor: pointer;
	position: relative;
	display: block;
	width: 100%;

	span {
	  font-size: 1.2em;
	  content: "";
	  letter-spacing: 0;
	  width: 2.2em;
	  height: 1.2em;
	  line-height: 1.2em;
	  border-radius: 14px;
	  display: block;
	  text-align: center;
	  background: $bgCheckbox;
	  color: transparent;
	  margin-right: $spacing;
	  position: relative;

	  &:before {
		content: "";
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: $bgCheckSlider;
		position: absolute;
		left: 4px;
		top: 3px;
		opacity: 1;
		transition: 150ms left ease-in-out;
		z-index: 10;
	  }

	  &:after {
		content: attr(data-off) " ";
		display: inline-block;
		position: absolute;
		left: calc(100% + 5px);
		color: $colorMedium;
		font-size: rem($fontSize);
		word-break: normal;
	  }
	}
  }
  input:checked + span:before {
	left: rem(17px);
  }
  input:checked + span {
	background: $bgSubmit;

	&:after {
	  content: attr(data-on) " ";
	}
  }
  input {
	display: none;
  }

  table {
	font-size: rem($fontSize);
	td {
	  vertical-align: top;
	  padding-bottom: $spacing;
	  word-break: break-word;
	}
	td:first-child {
	  font-weight: bold;
	  padding-right: 5px;
	  width: 50%;
	}
  }
}
#privacyController {
  & hr + p {
	opacity: 0.7;
  }
  p {
	margin-bottom: $spacing;
  }
}

#privacy-msg {
  padding: $spacing 35px 0 $spacing;
  font-size: rem($fontSize);
  line-height: rem(14px);
}

#privacySettings {
  max-height: 0;
  height: 0;
  transition: 600ms all ease-in;
  overflow-y: scroll;
  background: $bgForm;
  color: $colorMedium;

  .open & {
	max-height: 40vh;
	height: auto;
  }
}

#privacyButtons {
  padding: 0 $spacing $spacing $spacing;
  display: flex;
  justify-content: space-between;

  #privacySubmit {
	background: $bgSubmit;
	color: $colorLight;
  }
}

.privacyButton {
  border: none;
  background: rgba(255, 255, 255, 1);
  color: $colorMedium;
  font-size: rem($fontSize);
  line-height: rem(31px);
  padding: 0 rem(10px);
  cursor: pointer;
  transition: 150ms all ease-in-out;
  border-radius: $borderRadius;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-decoration: none;

  &:first-of-type {
	margin-right: $spacing;
  }

  &:hover {
	background: rgba(255, 255, 255, 0.8);
  }
}

#privacyController.open .privacyButton:last-child + .privacyButton {
  opacity: 0;
  visibility: hidden;
}

#privacyHide {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  line-height: 27px;
  width: 27px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

#privacyFooter {
  padding: 0 $spacing;
  text-align: right;
  line-height: 30px;
  font-size: rem(11px);

  a {
	margin-left: $spacing;
	color: #b3b5b8;
  }
}

.privacyControlBadge {
  body.privacyControlIsOpen & {
	left: -100%;
  }

  transform: rotate(90deg) translateX(-100%);
  display: inline-block;
  position: fixed;
  left: 0;
  bottom: 45px;
  transform-origin: bottom left;
  background: $bgMain;
  color: $colorLight;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  font-size: rem(12px);
  line-height: rem(12px);
  text-decoration: none;
  padding: $spacing;
  transition: 300ms all ease-in-out;
}

.privacyControlPlaceholder {
  text-align: center;
  font-size: rem(12px);
  line-height: rem(16px);

  .privacyButton {
	margin-bottom: $gaps;
	display: inline-block;
	color: $colorDark;
  }
  p {
	@include breakpoint(small) {
	  max-width: 50%;
	}
	font-size: rem(11px);
  margin: 0 auto;
  color:$dark;
  }

  &:not(.enabled) {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
}

#privacyControlReset {
  opacity: 0.7;
}
